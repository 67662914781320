section{
	background-color: #eee;
}
.front-logo{
	width: 185px;
}

.gradient-custom-2 {
/* fallback for old browsers */
background: #fccb90;

/* Chrome 10-25, Safari 5.1-6 */
background: -webkit-linear-gradient(to right, #a3cb13, hsl(90deg 70% 42%), #4bab2a, hsl(120deg 54% 41%));

/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background: linear-gradient(to right, #a3cb13, hsl(90deg 70% 42%), #4bab2a, hsl(120deg 54% 41%));
}

@media (min-width: 768px) {
.gradient-form {
height: 100vh !important;
}
}
@media (min-width: 769px) {
.gradient-custom-2 {
border-top-right-radius: .3rem;
border-bottom-right-radius: .3rem;
}
}
.app-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 8px;
/*  font-size: 32px;*/
}

th {
  background-color: #0d6efd;
}

td {
  background-color: #9298a021;
}

.sublistform {
  display: flex;
  gap: 5px;
}

.sublistform td:last-child {
  display: flex;
  justify-content: space-evenly;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
}
.show {display: block;}
.imageprev{
  height:40px;
  width:40px;
  padding:5px;
}
.tblwidth{
  width:250px;
}
.main-lbl{
  display:flex;

}


/*/////////////////////////////////////////////////////////////////////////////*/
.flatTable {
  width: 100%;
  min-width: 500px;
  border-collapse: collapse;
  font-weight: bold;
  color: #0b0b0b;;
}
.flatTable tr {
/*  height: 50px;*/
  background: 0d6efd00;
/*  border-bottom: rgba(0, 0, 0, 0.05) 1px solid;*/
}
.flatTable td {
  box-sizing: border-box;
  padding-left: 30px;
      padding-top: 0;
    padding-bottom: 8px;
}
.flatTable td.val{
  font-weight: 400;
}
.flatTable td.val2{
  font-weight: 400;
  color:red;
}
.flatTable .titleTr {
/*  height: 70px;*/
  color: #f6f3f7;
  background: #65b423;
  border: 0px solid;
      white-space: nowrap;
}
.flatTable .plusTd {
  background: url(https://i.imgur.com/3hSkhay.png) center center no-repeat, rgba(0, 0, 0, 0.1);
}
.flatTable .controlTd {
  position: relative;
  width: 80px;
  background: url(https://i.imgur.com/9Q5f6cv.png) center center no-repeat;
  cursor: pointer;
}
.flatTable .headingTr {
  height: 30px;
  background: #63acb7;
  color: #f6f3f7;
  font-size: 8pt;
  border: 0px solid;
}
.button {
  text-align: center;
  cursor: pointer;
}
.sForm {
  position: absolute;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100%;
  background: #d4d1d5;
  overflow: hidden;
  transition: width 1s, right 0.3s;
  padding: 0px;
  box-sizing: border-box;
}
.sForm .close {
  float: right;
  height: 70px;
  width: 80px;
  padding-top: 25px;
  box-sizing: border-box;
  background: rgba(255, 0, 0, 0.4);
}
.sForm .title {
  width: 100%;
  height: 70px;
  padding-top: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.1);
}
.open {
  right: 0;
  width: 400px !important;
}
.settingsIcons {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  overflow: hidden;
}

/*////////////////////////////////////////////////////////*/


@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,500&display=swap'); */
/*background: linear-gradient(45deg, #0cb307, #5eeb32);*/
/*background: linear-gradient(45deg, #0cb307, #5eeb32);*/

/*.nav-bar {
	background: linear-gradient(45deg,#fff,#fff)!important;
	color: #000;
	box-shadow: 1px 1px 17px #e5e5e5!important;
	height: 60px;
	position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}*/
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;700;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap'); */

/* @font-face {
    font-family: 'Nunito', sans-serif;
    src:  url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,500&display=swap');;
} */

body {
    /* font-family: 'Poppins', sans-serif; */
    /* font-family: 'Source Sans Pro', sans-serif; */
    /* font-family: 'PT Sans', sans-serif; */
    /* font-family: 'Heebo', sans-serif; */
    /* font-family: 'Play', sans-serif; */
    font-family: 'Nunito', sans-serif!important;
    
}
.nav-bar {
    background: linear-gradient(45deg, #0cb307, #5eeb32)!important;
    color: #000;
    /* box-shadow: 1px 1px 17px #adadad!important; */
    box-shadow: 1px 1px 17px #6666666b!important;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
/*.nav-bar .brand-name {
	color: #000;
}*/
.nav-bar .brand-name {
    color: #fff;
    margin: 0;
    font-weight: 700;
}
/* .nav-bar .brand-name:hover {
    animation: pop;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transition: ease-in-out 5s;
}

@keyframes pop {
    0%{       
        box-shadow: 1px 10px 10px #b9b9b9;
    }
    50% {        
        box-shadow: 1px 10px 10px #636363;
    }
    100% {        
        box-shadow: 1px 10px 10px #a5a5a5;
    }
} */
.nav-bar .brand-logo {
    height: 60px!important;
    width: 60px!important;
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative; 
    top: 6px;    
}
.nav-bar .link {
	display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    width: 187px;
}
/*.nav-bar .toggle-btn {
	color: #00ed49!important;
    font-size: 18px;	
}*/
.nav-bar .toggle-btn {
    color: #ffffff!important;
    font-size: 18px;
}
.nav-bar .toggle-btn:focus {
    box-shadow:unset!important;
}

/*.nav-bar .logout {
	background: linear-gradient(45deg, #0cb307, #5eeb32);
    padding: 8px 20px;
    display: flex;
    flex-direction: revert;
    border-radius: 6px;
    box-shadow: 0px 0px 13px #dbdbdb;
    margin-right: 30px;
}*/
.nav-bar .logout {
    background: linear-gradient(45deg, #ffffff, #ffffff);
    padding: 8px 20px;
    display: flex;
    flex-direction: revert;
    border-radius: 6px;
    box-shadow: 0px 0px 13px #dbdbdb;
    margin-right: 30px;
}
/*.nav-bar .logout .user {
	color: #fff;
	letter-spacing: 0.7px;
}*/
.nav-bar .logout .user {
    color: #00d310;
    letter-spacing: 0.3px;
}
.nav-bar .logout .cl-wh {
   color: #00d310!important;
}




@media screen and (max-width : 426px) {
    .nav-bar {
            display: flex;
            align-items: center;
            justify-content: space-between!important;
    }
    .nav-bar .link {
        display: flex;
        align-items: center;
        text-decoration: none;
        justify-content: start;
        width: 187px;
        margin-left: 10px;
    }
    .nav-bar .toggle-btn {
        color: #ffffff!important;
        font-size: 18px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: start;
        padding-left: 18px;
    }
    .nav-bar .logout {
        background: linear-gradient(45deg, #ffffff, #ffffff);
        padding:5px 12px;
        display: flex;
        flex-direction: revert;
        border-radius: 6px;
        box-shadow: 0px 0px 13px #dbdbdb;
        margin-right: 12px;
    }
    .nav-bar .brand-name {
        color: #fff;
        font-size: 21px;
    }
    .nav-bar .brand-logo {
        height: 54px!important;
        width: 54px!important;
        display: flex;
        align-items: center;
        margin-right: 10px;
        position: relative;
        top: 5px;
    }
}

@media (max-width: 426px)
.d-md-inline-block {
    display: inline-block !important;
}


                        /*SIDENAV CSS*/

.sidebar {
    background: #fff!important;
    /* box-shadow: 1px 1px 17px #ebebeb!important; */
    box-shadow: 1px 1px 17px #6666661f!important;
    transition: 1s ease-in-out!important;
}  
.sidebar:hover .nav{
    transition: .3s ease-in-out!important;
}
.sidebar .navLink {
    color: #4a4a4a!important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .2px;
    margin: 6px 0;
    transition: 1s ease-in-out!important;
} 

.sidebar .navLink .icon {
    color: #5e5e5e!important;
    margin-right: 12px!important;
    height: 19px;
    width: 19px;
}
.sidebar .nav-link {
    transition: unset!important;
}
.sidebar .navLink:hover {
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    color: #fff!important;
    box-shadow: 1px 1px 15px #ddd;
    font-weight: 500;
/*    transition: .1s ease-in-out!important;*/
} 
.sidebar .navLink:hover .icon-arrow {   
    color: #fff!important;
    font-weight: 700!important;
}  
.sidebar .navLink:hover .navLink {   
    color: #fff!important;
}  
.sidebar .navLink:hover .icon{   
    color: #fff!important;

} 

.sidebar .icon-arrow {
    color: #222222!important;
}                      


.sidebar .navLink.active {
    background: linear-gradient(45deg, #0cb307, #5eeb32)!important;
    color: #fff!important;
    font-weight: 500!important;
    box-shadow: 1px 1px 15px #ccc;
} 
.sidebar .navLink .icon.active {
/*    background: linear-gradient(45deg, #0cb307, #5eeb32)!important;*/
    color: #fff!important;   
}


.sb-sidenav-menu-nested {
    margin-left: 0!important;
}
.sidebar .sub {
    padding-left: 25px!important;
}


                    /* FOOTER CLASS */                

.footer {
	background: #fff!important;
	margin-top: 0px!important;
    padding: 25px 20px;
}








                /*  VIEW COURIER CLASS  */

.ViewCourier {
    margin: 40px 15px;
}

.ViewCourier .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.ViewCourier .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.ViewCourier .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.ViewCourier .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}



                    /*  ADD COURIER CLASS    */

.Courier {
    margin: 40px 15px;
}

.Courier .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;

}

.Courier .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.Courier .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.Courier .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}  


.Courier .card .card-body .btn-align{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.Courier .card .card-body button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
    /* width: 150px; */
}         

.Courier .card .card-body form .form-group {
    margin: 0px 25px 18px 25px!important;
}  

.Courier .card .card-body form .form-group label {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    letter-spacing: .4px;

}  
.Courier .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
   
}   
.Courier .card .card-body form .form-group input:focus {
     box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.Courier .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.Courier .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.Courier .card .card-body form .form-group textarea {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}       
.Courier .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.Courier .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.Courier .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.Courier .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
} 
.Courier .card .card-body form .form-group input[type="checkbox"]:focus {
    animation: unset;    
}


                    /*  EDIT COURIER CLASS    */

.EditCourier {
    margin: 40px 15px;
}

.EditCourier .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.EditCourier .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.EditCourier .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.EditCourier .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        


.EditCourier .card .card-body .btn-align{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.EditCourier .card .card-body button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
    /* width: 150px; */
}         

.EditCourier .card .card-body form .form-group {
    margin: 0px 25px 18px 25px!important;
}  

.EditCourier .card .card-body form .form-group label {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    letter-spacing: .4px;

}  
.EditCourier .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}  
.EditCourier .card .card-body form .form-group input:focus {
     box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.EditCourier .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.EditCourier .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.EditCourier .card .card-body form .form-group textarea {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}       
.EditCourier .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.EditCourier .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.EditCourier .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.EditCourier .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
} 
.EditCourier .card .card-body form .form-group input[type="checkbox"]:focus {
    animation: unset!important;
}












                    /*  VIEW COURIER MODE CLASS    */


.ViewCourierMode {
    margin: 40px 15px;
}

.ViewCourierMode .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.ViewCourierMode .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.ViewCourierMode .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.ViewCourierMode .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        











                    /*  ADD COURIER MODE CLASS    */


.CourierMode {
    margin: 40px 15px;
}

.CourierMode .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.CourierMode .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.CourierMode .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.CourierMode .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}   

.CourierMode .card .card-body button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
}         

.CourierMode .card .card-body form .form-group {
    margin: 0px 0 30px 0!important;
}  
.CourierMode .card .card-body form .form-group label {
    color: #818181;
    font-size: 14px;
    margin-bottom: 7px;
    letter-spacing: .7px;
}  
.CourierMode .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}   
.CourierMode .card .card-body form .form-group input:focus {
     box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.CourierMode .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.CourierMode .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.CourierMode .card .card-body form .form-group textarea {
    background: #fff;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}       
.CourierMode .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.CourierMode .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.CourierMode .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.CourierMode .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}      















                    /*  EDIT COURIER MODE CLASS    */


.EditCourierMode {
    margin: 40px 15px;
}

.EditCourierMode .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.EditCourierMode .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.EditCourierMode .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.EditCourierMode .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        

.EditCourierMode .card .card-body button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
}         

.EditCourierMode .card .card-body form .form-group {
    margin: 0px 0 30px 0!important;
}  
.EditCourierMode .card .card-body form .form-group label {
    color: #818181;
    font-size: 14px;
    margin-bottom: 7px;
    letter-spacing: .7px;
}  
.EditCourierMode .card .card-body form .form-group input {
    background: #f3f3f3;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}   
.EditCourierMode .card .card-body form .form-group input:focus {
    box-shadow: unset!important;
}
.EditCourierMode .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
}
.EditCourierMode .card .card-body form .form-group textarea {
    background: #f3f3f3;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}       
.EditCourierMode .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.EditCourierMode .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.EditCourierMode .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.EditCourierMode .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}      






                    /*  ADMIN SITE ENTRY CLASS    */


.AdminSite {
    margin: 40px 15px;
}

.AdminSite .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.AdminSite .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.AdminSite .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.AdminSite .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        








                    /*  CREATE SITE ENTRY CLASS    */


.CreateSite {
    margin: 40px 15px;
}

.CreateSite .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.CreateSite .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.CreateSite .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.CreateSite .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        
.CreateSite .card .card-body .btn-align {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}        
.CreateSite .card .card-body button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
    /* width: 150px; */
}         

.CreateSite .card .card-body form .form-group {
    margin: 25px 18px 25px 0!important;
}  
.CreateSite .card .card-body form .form-group label {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    letter-spacing: .4px;
}  
.CreateSite .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}
.CreateSite .card .card-body form .form-group input:focus {
    box-shadow: unset!important;
   border-bottom: 2px solid #00ed49;
   transition: .4s ease-in-out;
   animation: flow;
   animation-duration: .2s;
}
.CreateSite .card .card-body form .form-group select:focus {
   box-shadow: unset!important;
   border-bottom: 2px solid #00ed49;
   transition: .4s ease-in-out;
   animation: flow;
   animation-duration: .2s;
}
.CreateSite .card .card-body form .form-group textarea:focus {
   box-shadow: unset!important;
   border-bottom: 2px solid #00ed49;
   transition: .4s ease-in-out;
   animation: flow;
   animation-duration: .2s;
}
.CreateSite .card .card-body form .form-group textarea {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}   
.CreateSite .card .card-body form .form-group select {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}     
.CreateSite .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.CreateSite .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.CreateSite .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.CreateSite .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}  







                    /*  UPDATE SITE ENTRY CLASS    */

.UpdateSite {
    margin: 40px 15px;
}

.UpdateSite .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.UpdateSite .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.UpdateSite .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.UpdateSite .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}
.UpdateSite .card .card-body .btn-align {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}        
.UpdateSite .card .card-body button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
    /* width: 150px; */
}         

.UpdateSite .card .card-body form .form-group {
    margin: 0px 25px 18px 25px!important;    
}  

.UpdateSite .card .card-body form .form-group label {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    letter-spacing: .4px;
}  
.UpdateSite .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}
.UpdateSite .card .card-body form .form-group input[type="date"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}  
.UpdateSite .card .card-body form .form-group input[type="datetime-local"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}  
.UpdateSite .card .card-body form .form-group input:focus {
    box-shadow: unset!important;
   border-bottom: 2px solid #00ed49;
   transition: .4s ease-in-out;
   animation: flow;
   animation-duration: .2s;
}
.UpdateSite .card .card-body form .form-group select:focus {
   box-shadow: unset!important;
   border-bottom: 2px solid #00ed49;
   transition: .4s ease-in-out;
   animation: flow;
   animation-duration: .2s;
}
.UpdateSite .card .card-body form .form-group textarea:focus {
   box-shadow: unset!important;
   border-bottom: 2px solid #00ed49;
   transition: .4s ease-in-out;
   animation: flow;
   animation-duration: .2s;
}
.UpdateSite .card .card-body form .form-group textarea {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}   
.UpdateSite .card .card-body form .form-group select {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}     
.UpdateSite .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.UpdateSite .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.UpdateSite .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.UpdateSite .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}  








      










                    /*  VIEW ACKNOWLEDGE SITE ENTRY CLASS    */


.ViewSiteEntry_acknowledge {
    margin: 40px 15px;
}

.ViewSiteEntry_acknowledge .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.ViewSiteEntry_acknowledge .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.ViewSiteEntry_acknowledge .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.ViewSiteEntry_acknowledge .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        








                    /*  ADD ACKNOWLEDGE SITE ENTRY CLASS    */


.AddSiteEntry_Acknowledge {
    margin: 40px 15px;
}

.AddSiteEntry_Acknowledge .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.AddSiteEntry_Acknowledge .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.AddSiteEntry_Acknowledge .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.AddSiteEntry_Acknowledge .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}      

.AddSiteEntry_Acknowledge .card .card-body button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
}         

.AddSiteEntry_Acknowledge .card .card-body form .form-group {
    margin: 0px 0 30px 0!important;
}  
.AddSiteEntry_Acknowledge .card .card-body form .form-group label {
    color: #818181;
    font-size: 14px;
    margin-bottom: 7px;
    letter-spacing: .7px;
}  
.AddSiteEntry_Acknowledge .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}   
.AddSiteEntry_Acknowledge .card .card-body form .form-group input:focus {
     box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.AddSiteEntry_Acknowledge .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.AddSiteEntry_Acknowledge .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.AddSiteEntry_Acknowledge .card .card-body form .form-group textarea {
    background: #fff;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}   
.AddSiteEntry_Acknowledge .card .card-body form .form-group select {
    background: #fff;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}     
.AddSiteEntry_Acknowledge .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.AddSiteEntry_Acknowledge .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.AddSiteEntry_Acknowledge .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.AddSiteEntry_Acknowledge .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}      

.AddSiteEntry_Acknowledge .card .card-body form .form-group input[type="date"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    /* animation: unset!important; */
    animation-duration: .2s;
}

.AddSiteEntry_Acknowledge .card .card-body form .form-group input[type="datetime-local"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    /* animation: unset!important; */
    animation-duration: .2s;
}







                    /*  EDIT ACKNOWLEDGE SITE ENTRY CLASS    */


.EditSiteEntry_Acknowledge {
    margin: 40px 15px;
}

.EditSiteEntry_Acknowledge .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.EditSiteEntry_Acknowledge .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.EditSiteEntry_Acknowledge .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.EditSiteEntry_Acknowledge .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        






                    /*  ADMIN HO CLASS    */


.AdminHo {
    margin: 40px 15px;
}

.AdminHo .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.AdminHo .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.AdminHo .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.AdminHo .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        










                    /*  CREATE HO CLASS    */


.CreateHo {
    margin: 40px 15px;
}

.CreateHo .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
    margin-top: 54px!important;
}

.CreateHo .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.CreateHo .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.CreateHo .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        
.CreateHo .card .card-body .btn-align {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.CreateHo .card .card-body button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
    /* width: 150px; */
}         

.CreateHo .card .card-body form .form-group {
    margin: 0px 25px 18px 25px!important;
}  
.CreateHo .card .card-body form .form-group label {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    letter-spacing: .4px;
}  
.CreateHo .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}   
.CreateHo .card .card-body form .form-group input:focus {
     box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.CreateHo .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.CreateHo .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.CreateHo .card .card-body form .form-group textarea {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 4px;
    padding: 6px 8px;
    letter-spacing: 0.8px;
}   
.CreateHo .card .card-body form .form-group select {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;
}     
.CreateHo .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.CreateHo .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.CreateHo .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.CreateHo .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}      

.CreateHo .card .card-body form .form-group input[type="date"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}

.CreateHo .card .card-body form .form-group input[type="datetime-local"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}










                    /*  UPDATE HO CLASS    */


.UpdateHo {
    margin: 40px 15px;
}

.UpdateHo .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
    margin-top: 54px!important;
}

.UpdateHo .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.UpdateHo .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.UpdateHo .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        

.UpdateHo .card .card-body .btn-align{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.UpdateHo .card .card-body button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
    /* width: 150px; */
}         

.UpdateHo .card .card-body form .form-group {
    margin: 0px 25px 18px 25px!important;
}  
/* .UpdateHo .card .card-body form .form-group:nth-child(1){
    margin: 15px 0 30px 0!important;
}   */
.UpdateHo .card .card-body form .form-group label {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
    letter-spacing: .4px;

}  
.UpdateHo .card .card-body form .form-group input {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;

   
}   

@keyframes flow {
    0%{
        position: relative;
        border-bottom: 2px solid #00ed49;
        width: 0%;    
        left: 50%;
    }    
    100%{
        position: relative;
        left: 0%;
        border-bottom: 2px solid #00ed49;
        width: 100%;
    }
}
.UpdateHo .card .card-body form .form-group input:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: flow;
    animation-duration: .2s;
}
.UpdateHo .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.UpdateHo .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.UpdateHo .card .card-body form .form-group textarea {
    background: transparent;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
     animation: flow;
    animation-duration: 4s;
}   
.UpdateHo .card .card-body form .form-group select {
    background: #fff;
    color: #464646;
    font-size: 12.5px;
    margin-bottom: 7px;
    border: 1px solid #62626254;
    /* border-bottom: 2px solid #f3f3f3; */
    border-radius: 3px;
    padding: 7px 8px;
    letter-spacing: 0.4px;

}     
.UpdateHo .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.UpdateHo .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.UpdateHo .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.UpdateHo .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}    

.UpdateHo .card .card-body form .form-group input[type="date"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}

.UpdateHo .card .card-body form .form-group input[type="datetime-local"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}







                    /*  HoRec_Acknowledge CLASS    */


.HoRec_Acknowledge {
    margin: 40px 15px;
}

.HoRec_Acknowledge .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.HoRec_Acknowledge .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.HoRec_Acknowledge .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.HoRec_Acknowledge .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        






                    /*  EDIT SITE ACTION CLASS    */


.SiteAction {
    margin: 40px 15px;
}

.SiteAction .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
    margin-top: 54px!important;
}

.SiteAction .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.SiteAction .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.SiteAction .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        

.SiteAction .card .card-body button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
}         

.SiteAction .card .card-body form .form-group {
    margin: 0px 0 30px 0!important;
}  
/* .SiteAction .card .card-body form .form-group:nth-child(1){
    margin: 15px 0 30px 0!important;
}   */
.SiteAction .card .card-body form .form-group label {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 7px;
    letter-spacing: .5px;
}  
.SiteAction .card .card-body form .form-group span {
    margin-left: 10px;    
    font-size: 14px;
    letter-spacing: .4px;
    color: #5c5c5c;
}
.SiteAction .card .card-body form .form-group input {
    background: #f8f8f8;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f8f8f8;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
    position: relative;
   
}   

.SiteAction .card .card-body form .form-group input:focus {
    box-shadow: unset!important;
    /* border-bottom: 2px solid #00ed49; */
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}
.SiteAction .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.SiteAction .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.SiteAction .card .card-body form .form-group textarea {
    background: transparent;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
     animation: flow;
    animation-duration: 4s;
}   
.SiteAction .card .card-body form .form-group select {
    background: transparent;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}     
.SiteAction .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.SiteAction .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.SiteAction .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.SiteAction .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}    

.SiteAction .card .card-body form .form-group input[type="date"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}

.SiteAction .card .card-body form .form-group input[type="datetime-local"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}








                        /* VIEW RECIEVER ACKNOWLEDGE */



.Rec_Acknowledge {
    margin: 40px 15px;
}

.Rec_Acknowledge .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
   margin-top: 54px!important;
}

.Rec_Acknowledge .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.Rec_Acknowledge .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.Rec_Acknowledge .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        







                   /*  EDIT BY HO CLASS    */


.ByHo {
    margin: 40px 15px;
}

.ByHo .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
    margin-top: 54px!important;
}

.ByHo .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.ByHo .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.ByHo .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        

.ByHo .card .card-body button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
}         

.ByHo .card .card-body form .form-group {
    margin: 0px 0 30px 0!important;
}  
.ByHo .card .card-body form .form-group:nth-child(1){
    margin: 15px 0 30px 0!important;
}  
.ByHo .card .card-body form .form-group label {
    color: #818181;
    font-size: 14px;
    margin-bottom: 7px;
    letter-spacing: .7px;
}  
.ByHo .card .card-body form .form-group input {
    background: #f8f8f8;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f8f8f8;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
    position: relative;
   
}   

.ByHo .card .card-body form .form-group input:focus {
    box-shadow: unset!important;
    /* border-bottom: 2px solid #00ed49; */
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}
.ByHo .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.ByHo .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.ByHo .card .card-body form .form-group textarea {
    background: transparent;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
     animation: flow;
    animation-duration: 4s;
}   
.ByHo .card .card-body form .form-group select {
    background: transparent;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}     
.ByHo .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.ByHo .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.ByHo .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.ByHo .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}    

.ByHo .card .card-body form .form-group input[type="date"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}

.ByHo .card .card-body form .form-group input[type="datetime-local"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}











                   /*  EDIT BY SITE CLASS    */


.BySite {
    margin: 40px 15px;
}

.BySite .card {
   box-shadow: 1px 1px 12px #d9d9d9;
   border: none;
   border-radius: 20px;
    margin-top: 54px!important;
}

.BySite .card .card-header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   border: none;
   padding: 15px 23px;
   background: #f3f3f3;
   border-top-right-radius: 20px;
   border-top-leftt-radius: 20px;
}

.BySite .card .card-header a{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
}

.BySite .card .card-header a:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px #ccc;
}        

.BySite .card .card-body button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 10px 0;
}         

.BySite .card .card-body form .form-group {
    margin: 0px 0 30px 0!important;
}  
.BySite .card .card-body form .form-group:nth-child(1){
    margin: 15px 0 30px 0!important;
}  
.BySite .card .card-body form .form-group label {
    color: #818181;
    font-size: 14px;
    margin-bottom: 7px;
    letter-spacing: .7px;
}  
.BySite .card .card-body form .form-group input {
    background: #f8f8f8;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f8f8f8;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
    position: relative;
   
}   

.BySite .card .card-body form .form-group input:focus {
    box-shadow: unset!important;
    /* border-bottom: 2px solid #00ed49; */
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}
.BySite .card .card-body form .form-group textarea:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.BySite .card .card-body form .form-group select:focus {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
     animation: flow;
    animation-duration: .2s;
}
.BySite .card .card-body form .form-group textarea {
    background: transparent;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
     animation: flow;
    animation-duration: 4s;
}   
.BySite .card .card-body form .form-group select {
    background: transparent;
    color: #464646;
    font-size: 14.5px;
    margin-bottom: 7px;
    border: none;
    border-bottom: 2px solid #f3f3f3;
    border-radius: 2px;
    padding: 9px 17px;
    letter-spacing: 0.8px;
}     
.BySite .card .card-body form .form-group input[type="checkbox"] {
    position: relative;
    top: 30px;
    left: 0px;

} 
.BySite .card .card-body form .form-group input[type="checkbox"]::before {
    content: '';
    background: #f3f3f3;
    height: 25px;
    width: 25px;
    border: 1px solid #cfcfcf;   
    position: absolute; 
    border-radius: 50px; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
} 
.BySite .card .card-body form .form-group input[type="checkbox"]:checked::before {
    position: absolute;
    content: '';
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    height: 25px;
    width: 25px;
    border-radius: 50px;
    
     
} 
.BySite .card .card-body form .form-group input[type="checkbox"]:checked::after {
    position: absolute;
    content: '';       
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 08px;   
    z-index: 9999999;    
    transform: rotate(220deg);
    top: -18%;
    left: 13%;
    
}    

.BySite .card .card-body form .form-group input[type="date"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}

.BySite .card .card-body form .form-group input[type="datetime-local"]:focus  {
    box-shadow: unset!important;
    border-bottom: 2px solid #00ed49;
    transition: .4s ease-in-out;
    animation: unset!important;
    animation-duration: .2s;
}






.searchbox {
    height: 32px;
    width: 255px;
    border-radius: 32px!important;    
    border: 1px solid #e5e5e5;
    padding: 0 45px 0 19px;
}
.searchbox:focus{    
    outline: 0!important;
}

.clearbtn {
    background: #dbac00!important;
    border-top-right-radius: 32px!important;
    border-bottom-right-radius: 32px!important;
    padding: 0px 16px!important;
    position: absolute!important;
    height: 32px!important;
    border: none!important!important;
    box-shadow: unset!important;
}


.export {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e5e5e5;
    padding: 0px 15px;
    border-radius: 30px;
    margin-right: 10px;
}

.export small{
    margin-right: 10px;
    font-size: 14px;
}

.export span i{   
    font-size: 11px!important;
}












@media screen and (max-width : 768px) {
    .card .card-header h4 {
        font-size: 18px!important;
        display: block;
        margin-top: 27px;
        padding-left: 0px!important;        
    }
}

.card {
    margin-top: 54px!important;
}

.card .card-icon {
    position: absolute;
    top: -27px;
    left: 18px;
    padding: 12px 21px;
    font-size: 21px;
    background: #1fc71f;
    color: #fff;
    border-radius: 11px;
    box-shadow: 0px 0px 15px #ccc;
}
@media screen and (max-width : 768px) {
    .card .card-icon {
         padding: 9px 18px;
    }
} 
.card .card-header h4 {
    font-size: 21px;
    padding-left: 70px;
}
 .card .card-body  table {
/*   border-radius: 50px!important;*/
   box-shadow: 0px 0px 14px #ccc;
   border: 1px solid #f3f3f3;
}
.card .card-body  table thead {
    background: #f3f3f3;
/*    background: linear-gradient(45deg, #0cb307, #55e329);*/
/*    background: #0edd74;*/
    color: #646464;
    box-shadow: 1px 1px 14px #ccc;
}
.card .card-body  table tbody th:nth-of-type(1){
    padding: 10px 5px!important;
}
.card .card-body  table thead th {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .5px;
    width: 0px;
    vertical-align: middle;
    text-align: center;
    padding: 10px 0px;
}
.card .card-body  table tbody {
   background: #fff!important;
   border-top: 2px solid transparent!important;
}
.card .card-body  table tbody td {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: .3px;
    color: #343434;
    vertical-align: middle;
    text-align: center;
    padding: 10px 4px;
}
.card .card-body  table tbody td.td-flex {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    /* padding: 28px 15px; */
}
.card .card-body  table tbody button {
    background: transparent!important;
    padding: 3px 10px!important;
    font-size: 13px;
    letter-spacing: 0.7px;
    box-shadow: unset!important;
    margin: 0!important;    
}
.card .card-body  table tbody button span {
    background: #19bbd3;
    color: #fff;
    padding: 3px 10px!important;
    font-size: 13px;
    letter-spacing: 0.7px;
    border-radius: 3px;
}
.card .card-body  table tbody input {
   border: 1px solid #e1e1e1;
   outline: 0!important;
   font-size: 14px;
   width: 100px;
   height: 30px;
   padding : 6px 8px;
}
.card .card-body  table tbody input[type='checkbox'] {
    width: 20px!important;
    height: 14px;
    position: relative;
}
.card .card-body  table tbody input[type='checkbox']::before {
    content: '';
    position: absolute;
    top: 0px;
    background: #fff;
    border: 1px dotted #98cbff;
    height: 19px;
    width: 19px;
    border-radius: 0px;
}
.card .card-body  table tbody input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 3.5px;
    left: 4px;
    background: #7abeff;
    height: 11px;
    width: 11px;
    /* transform: translate(3px, 2px); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
}
.card .card-body  table tbody select {
   border: 1px solid #e1e1e1;
   outline: 0!important;
   font-size: 14px;
   width: 100px;
   height: 30px;
   padding: 0 5px;
   appearance: auto!important;
}
.card .card-body  table tbody input:focus {
   border: 1px solid #e1e1e1;
   outline: 0!important;
   box-shadow: unset!important;
}
.card .card-body  table tbody select:focus {
   border: 1px solid #e1e1e1;
   outline: 0!important;
   box-shadow: unset!important;
}
 
.table-icon {
    background: #00b737!important;
    color: #fff!important;
    padding: 2px 3px;
    border-radius: 4px;
}

.table-hdbox{
    background: #70cbe5;
    color: #fff;
    width: 150px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px
}
.table-hdbox svg{
    margin-right: 10px;
}
.table-hdbox h6{
    margin: 0;
    font-size: 15px;
}
.table-hdbox.pc {
    background: #c3dc63d1;
}
.table-hdbox.lico {
    width: 345px;
    background: #5ce3a6e0;
}

.acknowledgement {
    padding: 0 30px;
}
.acknowledgement thead th{
    font-size: 14px;
    color: #464646;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
}
.acknowledgement tbody{
    border-top: transparent!important;
}
.acknowledgement tbody td{
    font-size: 13px;
    font-weight: 500;
    vertical-align: middle;
    text-align: center;
}
.acknowledgement tbody td input[type='checkbox'] {
    width: 20px!important;
    height: 14px;
    position: relative;
}
.acknowledgement tbody td input[type='checkbox']::before {
    content: '';
    position: absolute;
    top: 0px;
    background: #fff;
    border: 1px dotted #67b2ff;
    height: 19px;
    width: 19px;
    border-radius: 0px;
}
.acknowledgement tbody td input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    /* top: 3.5px;
    left: 4px; */
    background: #7abeff;
    height: 11px;
    width: 11px;
    transform: translate(4px, 4px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
}
.acknowledgement .btn-align {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.acknowledgement button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none!important;
    padding: 7px 15px;
    background: linear-gradient(45deg, #0cb307, #5eeb32);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 1px 1px 9px #ddd;
    transition: .2s ease-in-out;
    margin: 0 10px 15px 0;
    color: #fff;
    /* width: 150px; */
}
.acknowledgement .table-hdbox{
    background: #70cce500;
    border: 1px solid #dddddd;
    border-bottom: none;
    color: #353535;
    width: 110px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px
}
.acknowledgement .table-hdbox svg{
    margin-right: 10px;
    color: #1db0da;
}
.acknowledgement .table-hdbox.pc svg{
   color: #dad13ed1;
}
.acknowledgement .table-hdbox.lico svg{
   color: #3ac687e0;
}
.acknowledgement .table-hdbox h6{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
}
.acknowledgement .table-hdbox.pc {
    background: #c4dc6300;
    width: 150px;
}
.acknowledgement .table-hdbox.lico {
    width: 328px;
    background: #5ce3a600;
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #f3f3f3;
    border-radius: 30px;
}

::-webkit-scrollbar-thumb {
    background: #d7d7d7;
/*    background: #00ed49;*/
/*    background: #7eeb9f;*/
/*    background: linear-gradient(45deg, #0cb307, #5eeb32);*/
    border-radius: 30px;
}


.btn-align {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mar-bottm {
    margin-top: 31px;
}

.loadingCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}


.td-flex {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
}


/*.css-1f43avz-a11yText-A11yText {
    position: relative!important;
}*/
.css-1s2u09g-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent!important;
    border-color: #fff!important;
    border-radius: 4px;
    border: 1px solid #62626254!important;
    font-size: 12.5px;
    letter-spacing: 0.4px;
    /* border-style: none!important; */
    /* border-bottom: 1px solid #f3f3f3!important; */
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
/*    -webkit-transition: all 100ms;*/
/*    transition: all 100ms;*/
    box-sizing: border-box;
}

.css-1pahdxg-control {
    webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent!important;
    overflow: hidden;
    border-color: #ffffff!important;
    border-radius: 4px;
    border: 1px solid #ccc!important;
    font-size: 12.5px;
    letter-spacing: 0.4px;
    border-width: 1px;
    padding: 4px 8px;
    box-shadow: 0 0 0 1px #ffffff!important;
    border-bottom: 1px solid #00ed49!important;
/*    transition: 1s ease-in-out!important;*/
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
/*    -webkit-transition: all 100ms;*/
/*    transition: all 100ms;*/
    box-sizing: border-box;
    animation: flow;
    animation-duration: .2s;
}
.css-b62m3t-container .css-26l3qy-menu {
    /* z-index: 99999999!important;
    display: block!important;
    white-space: nowrap!important;
    position: absolute!important;
    top: -95px!important; */
}
table .css-1s2u09g-control {
    width: 120px;
    height: 30px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent!important;
    overflow: hidden;
    /* border-color: hsl(0, 0%, 80%)!important; */
    border-radius: 4px;
    border: 1px solid #ddd!important;
    border-style: solid!important;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 30px;
    outline: 0!important;
    position: relative;
/*    -webkit-transition: all 100ms;*/
/*    transition: all 100ms;*/
    box-sizing: border-box;
}
table .css-319lph-ValueContainer {
    height: 30px;
}
/* table .css-14el2xx-placeholder {
    height: 25px;
} */
table .css-1s2u09g-control .css-14el2xx-placeholder {
    height: 30px;
    white-space: nowrap;
    transform: translate(-4px, 2px);
}
/* table .css-1s2u09g-control .css-14el2xx-placeholder {
    height: 25px;
    transform: translate(-13px, -1px);
} */
table .css-1pahdxg-control .css-14el2xx-placeholder {
    transform: translate(-16px, -4px);
    height: 20px;
}
.acknowledgement table .css-14el2xx-placeholder {
    height: 16px;
}
table .css-1hb7zxy-IndicatorsContainer {
    height: 30px;
}

table .css-1pndypt-Input {
    transform: translate(-7px, -6px)!important;
}
table .css-qc6sy-singleValue {
    transform: translate(0px, -1px)!important;
}

.table-responsive {
    overflow-x: auto!important;
    overflow-y: unset!important;
}
#react-select-13-listbox {     
    z-index: 99999999!important;   
    display: block!important;    
}
.css-4ljt47-MenuList{    
    z-index: 99999999!important;   
    display: block!important;   
    
   
}
.css-1n7v3ny-option{
    z-index: 99999999!important;   
    display: block!important;   
    
}

table .css-1pahdxg-control {
    z-index: 999999!important;
    width: 120px;
    height: 30px;
    min-height: 30px;
    border: 1px solid #ddd!important;
    animation: unset!important;
}


.F_icons .edit {
    background: transparent!important;
    padding: 11px!important;
    color: #039b12;
}

.F_icons .del {
    background: transparent!important;
    padding: 11px!important;
    color: #ed6464;
}


.card-gap {
    margin-top: 40px;
}

.card-gap:nth-of-type(1){
    margin-top: 10px;
}

.visibility {
    visibility: hidden;
}
@media screen and (max-width : 768px) {
    .visibility {
       display: none;
    }
}

.responsive-div {
    display: flex;
    flex-wrap: wrap;
}
.filter-form {

}
.filter-form label{
    color: #2f2f2f;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .4px;
}
.filter-form input{
    font-size: 12px;
    letter-spacing: 0.4px;
    border-radius: 50px;
}
.filter-form button{
    margin-top: 17px;
    background: #262825e6;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    border: none!important;
    border-radius: 50px;
    box-shadow: 1px 1px 10px #2424241c;
    outline: 0;
    width: 60px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
}
.filter-form button span{
    display: none;
    /* transition: 2s ease-in-out; */
    margin-left: 5px;
}
.filter-form button:hover {
    width: 100%!important;
    /* animation: leftToRight; */
    animation-duration: 5s;
    animation-iteration-count: infinite;
    /* transition: 2s ease-in-out; */
}
@keyframes leftToRight {
    0%{
        /* float: left; */
        width: 160px;
        position: relative;
        left: 0%;
    }   
    100% {
        width: 260px;
        position: relative;
        left: 100%;        
    }
}
.filter-form button:hover span{
    display: block!important;
    transition: 2s ease-in-out;
}
.filter-form.btn button{
    display: flex;
    align-items: center;
}
.filter-form.sitenm  .css-1s2u09g-control {
    border-radius: 50px;
    min-height: 20px;
    height: 32px;
}
.filter-form.sitenm  .css-1s2u09g-control .css-14el2xx-placeholder {
    height: 23px;
}
.filter-form.sitenm .css-1s2u09g-control .css-319lph-ValueContainer {
    transform: translate(3px, -1px);
}
.filter-form.sitenm .css-1pahdxg-control {
    border-radius: 50px;
    min-height: 20px!important;
    height: 32px!important;
}
.filter-form.sitenm .css-1pahdxg-control .css-14el2xx-placeholder {
    transform: translate(3px, -6px);
}
.filter-form.sitenm .css-1pahdxg-control .css-qc6sy-singleValue {
    transform: translate(3px, -6px);
}
.filter-form.sitenm .css-1pndypt-Input {
    transform: translate(3px, -6px)!important;
}
.filter-form.sitenm .css-1pahdxg-control .css-6j8wv5-Input input{
    transform: translate(-6px, -6px);
}

.side-gap {
    margin: 0 20px;
}